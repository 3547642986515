<template>
  <power-bi-report :report-id="reportId" />
</template>

<script>
export default {
  components: {
    PowerBiReport: () => import('@/modules/external_bi/components/PowerBiReport')
  },
  computed: {
    reportId() {
      return 'c812435e-c603-4c31-bf2a-21545c56bc33'
    }
  }
}
</script>
